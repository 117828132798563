import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
   <div className="container-fluid bg-info text-center">
      <footer className="container mt-5 pt-5 pb-1 bg-info ">
      <p className="lagcloud text-white">
          <Link to="/privacy">Privacy Policy</Link> | <Link to="/Terms">Terms & Conditions</Link>
        </p>
      <p className="lagcloud text-white">&copy; 2018–2023 Lagcloud-Solutions. &middot; </p>
      
      
      
    </footer>
   </div>
  );
}
export default Footer;
