import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import Header from "./component/Header";
import Home from "./page/Home";
import Services from "./page/Services";
import Aboutus from "./page/Aboutus";
import Contact from "./page/Contact";
import Privacydata from "./page/Privacydata";
import Myterms from "./page/Myterms";


function App() {
   return (
     <div>
       <Router>
       <Header />
         <Switch>
           <Route path="/" component={Home} exact />
           <Route path="/services" component={Services} />
           <Route path="/aboutus" component={Aboutus} />
           <Route path="/contact" component={Contact} />
           <Route path="/privacy" component={Privacydata}/>
           <Route path="/Terms" component={Myterms} />
         </Switch>
       </Router>
     </div>
   );
}
export default App;
