import React from 'react';


function About(){
    return(
        <div className='container-fluid' id='aboutbox'>
          <div className='container marketing'>
            <div className=' talk-about mb-5'>
        <div>
          <h2 className='about-header '><span>Why Lagcloud-Solutions?</span></h2>
        </div>
      </div>
      <div className='row featurette'>
        <div className='col-md-6 p-4'>
          <h2 className='about-header mt-4'>Why Our Customers Work With us</h2>
          <p className='lead mt-4'>
            <span className='text-white fw-bold'>Customized Excellence: </span> Your business is unique, and your software should reflect that. We specialize in crafting bespoke solutions that align with your specific needs and goals. From web and mobile applications to intricate software integrations, we've got you covered.<br />
          </p>
          <p className='lead'>
          <span className='text-white fw-bold'>Quality Assurance: </span> Our rigorous quality control process ensures that every line of code meets the highest standards. We take the guesswork out of software development, guaranteeing a seamless user experience.<br />
          </p>
          <p className='lead'>
            <span className='text-white fw-bold'>Dedicated Support:</span> Our commitment doesn't end with project delivery. We offer ongoing support and maintenance services, ensuring your software remains robust and up-to-date, even as your business evolves.<br />
        </p>
       </div>
        <div className='col-md-6'>
          <img src='./assets/images/about.jpg' alt='about' className='rounded d-none d-sm-block h_img'/>
        </div>
      </div>
      </div>   

        </div>

    );
}

export default About;