import React from "react"
import { Link } from "react-router-dom";

function Hero(){
    return (
        <section style={{margin:"50px 0px 0px"}} id="myCarousel" className="carousel slide mt-9" data-bs-ride="carousel">
        <div className="mt-5 mt-md-0">
          <div className="row">
            <div className="col-md-6">
              <div className="carousel-item active">    
              <div className="first-section">
                <div className="carousel-caption text-start mt-10">
                  <h1 className="hero-text">Great<span className="text-info"> Product</span> is <br />
                    built by great <span className="text-info">teams</span>  </h1>
                  <p className="hero-paragraph mt-4">
                    Ready to embark on your software development journey with <span className="text-info "> Lagcloud-Solution?</span> Whether you're a startup or an established enterprise, we're here to help you achieve your digital goals.              </p>
                  <p/>
                  
                   <Link to="/contact"><button type="button" class="btn btn-info">Lets get started!</button></Link> 
                </div>
              </div>
             </div>
           </div>
           <div className="col-md-6 col-xs-12 d-none d-sm-block mt-5">
            <div className="">
              <img src="./assets/images/heronew1.jpg" alt="homeImage" className="rounded h_img"/>
            </div>
          </div>
          </div>
        </div>
      </section>
    );
}

export default Hero;