import React from "react";
import mydata from "../mydata";
import Card from "../component/Card"
import Footer from "../component/Footer";

function Services() {
  return (
    <div>
        <div id="servicesbox">
      <div className="container-fluid">
        <div className="container marketing">
          <div className=" talk-about mb-5">
            <div>
              {" "}
              
            </div>
          </div>
          <div className="row featurette">
            <div className="col-md-6 p-4 text-center">
              <h2 className="about-header mt-4">
                Expert Software Development Services to Bring Your Vision to
                Life
              </h2>
              <p className="lead mt-4">
                Our software development company is dedicated to providing
                high-quality services that help businesses achieve their goals.
                We specialize in custom software development, creating tailored
                solutions that meet our clients' unique needs. Our team of
                experts has years of experience in software development, and we
                use cutting-edge technology to create innovative solutions that
                drive business growth. We take the time to understand our
                clients' needs, goals, and challenges, so we can create software
                that truly delivers value. Our services include everything from
                software design and development to quality assurance testing and
                ongoing support. We work closely with our clients throughout the
                entire development process, ensuring that our solutions meet
                their expectations and requirements.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="./assets/images/services.jpg" alt="services"
                className="d-none d-sm-block h_img"
              />
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div className="services p-3" id="myservices">
    <div className="container">
        <div className="row">
          {mydata.map((item, i) => {
            return (
              <Card 
                title={item.title}
                body={item.body}
                key={i}
              />
            );
          })}
        </div>
      </div>
    </div>
    <Footer />
    </div>
     
  );
}

export default Services;
