import React from 'react';
import TestCard from "./TestCard";

function Testimonials() {
  return (
    <div className="container mt-5 mb-5">
      <h3 className="text-center mb-4 ">What Our Clients say </h3>
      <TestCard />
    </div>
  );
}

export default Testimonials;
