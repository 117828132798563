import React from "react";
import Footer from "./Footer";

function Privacy() {
  return (
    <div>
      <div className="privacy">
        <h4 className="mb-2 text-center fs-2">Lag Cloud-Solutions Privacy Policy</h4>
        <h6 className="mb-5 text-center">Last Updated on Feb 19th, 2024</h6>
        <p>
          Thank you for joining lag cloud-solutions. We at lag cloud appreciate
          your privacy and want you to understand how we collect your data. This
          privacy Policy describes how Lag-cloud solution collects, uses,
          retains and processes your information when you as a person sign up to
          use lag cloud solutions website and services.
          <br /> By continuing to use and interact with our web app, you are
          consenting to the practices described in this privacy policy.
        </p>
        <h4 className="mt-5 mb-4 ">
          Our Privacy Policy Note explains:
        </h4>
        <ul className="policyNote text-decoration-none p-0 ">
          <li>Information we collect about you</li>
          <li>How we use your information</li>
          <li>How long we keep your information</li>
          <li>Privacy Rights of EU Residents</li>
          <li>Changes to this Privacy Notice</li>
          <li>How to Contact Us</li>
        </ul>
        <h4 className="mt-5 mb-4 ">
          Information We Collect About you.
        </h4>
        <p>
          We use your information to simply create an account and register you
          into our app. For instance, when you get to our ‘contact us page’ on
          the web page. This information is below.
        </p>
        <ul className="yourInfomation text-decoration-none p-0 ">
          <li>Name</li>
          <li>Email Address</li>
          <li>Number</li>
          <li>Company’s Name</li>
          <li>Message</li>
        </ul>
        <h4 className="mt-5 mb-4 ">How We Use Your Information</h4>
        <p>
          We use your information, your name, email to create an account on our
          app. We will store your contact information in order to notify
          interested partners when the app is Live.
        </p>
        <h4 className="mt-5 mb-4 ">
          How Long We Keep Your Information
        </h4>
        <p>
          We keep your information in the database for up to 12 months after
          which it will get renewed for another 12 months should you choose to
          continue with us. If you choose to go a different route then you have
          the right to request for your data in which we will provide your data.
        </p>
        <h4 className="mt-5 mb-5 ">
          Privacy Rights of EU Residents
        </h4>
        <h5 className="fw-lighter mb-3 fs-9">
          Privacy Rights regarding your Personal Information{" "}
        </h5>
        <p>
          This section provides specific information for California residents,
          as required under California privacy laws, including the California
          Consumer Privacy Act (“CCPA”) as well as other jurisdictions and
          regulations that allow for individual privacy rights such as the
          European Economic Area, including the United Kingdom, and the General
          Data Protection Regulation (“GDPR”).
        </p>
        <h5 className="fw-lighter mb-3 mt-5 fs-9">
          Explanation of Individual Rights
        </h5>
        <p className="mb-5">
          Right to a Copy/Access or Portability: You may have the right to
          request, free of charge, a copy of the specific pieces of Personal
          Information that we have collected about you in a readily usable
          format that allows you to transmit this information to another entity
          without hindrance.
        </p>
        <p className="mt-5">
          Right to Know: You may have the right to request, free of charge, that
          we provide certain information about how we have handled your Personal
          Information, including the categories of Personal Information
          collected; categories of sources of Personal Information; business
          and/or commercial purposes for collecting your Personal Information;
          categories of third parties/with whom we have shared your Personal
          Information; and whether we sell any categories of Personal
          Information to third parties (however, we do not sell your Personal
          Information).
        </p>
        <p className="mt-5">
          Right to Deletion: You may have the right to request deletion of your
          Personal Information that we have collected, subject to certain
          exemptions. Please note that we may need to retain certain information
          for record-keeping purposes and/or to complete any transactions that
          you began prior to requesting a change or deletion (e.g., when you
          make a purchase or enter a promotion, you may not be able to change or
          delete the Personal Information provided until after the completion of
          such purchase or promotion). We may also retain residual information,
          such as records to document that your request has been fulfilled.
        </p>
        <p className="mt-5">
          Right to Non-Discrimination: You may have the right not to receive
          discriminatory treatment on the basis of exercising your privacy
          rights under applicable.
        </p>
        <h4 className="mt-5 mb-2 ">Changes to this privacy</h4>
        <p className="mt-2">
          This notice of Privacy will always be updated by posting a revised
          version with “Last Updated on”. Each revised version will take effect
          on the date stated “Last Updated on” with prior notice of updated
          changes on how we use your information including through the email you
          have provided to us. Should you have any disagreement with the changes
          made, you may cancel your account at any time, and if you remain using
          our service, you have consented to any amendment of this Privacy
          Notice.
        </p>
        <h4 className="mt-5 ">How To Contact Us</h4>
        <p className="mt-2">
          You can contact our privacy officer with any questions by reaching out
          to us on accounts@lagcloud-solutions.com
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
