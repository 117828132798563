import React from "react"
import Privacy from "../component/Privacy";


function Privacydata() {
    return(
        <div className="privacydata">
            <Privacy />
        </div>
    );
}

export default Privacydata;