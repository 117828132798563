import emailjs from '@emailjs/browser';

function sendEmail(form) {
  return emailjs.sendForm('service_7fcv2pi', 'contact_form', form.current, {
    publicKey: 'gAE8HaxcRokZBJ8GS',
  });
}

export default sendEmail;


