import React from "react"
import Hero from "../component/Hero";
import Marketing from "../component/Marketing";
import About from "../component/About"
import Testimonials from "../component/Testimonials"
import Footer from "../component/Footer"
import { Link } from "react-router-dom";

function Home(){
    return(
        <main>
         <Hero />
         <Marketing />
         <About />
         <div class="text-center">
            <h2 class="services-header py-4">Ready to Innovate and Thrive?</h2>
            <p>Contact us today to discuss your project.</p>
            <Link to="/contact"><button  type="button" class="btn btn-outline-success btn-bg px-4 py-2">Contact us</button></Link>
        </div>
         <Testimonials />
         <Footer />
       </main>
    )
}

export default Home;