import React from "react";
function Card({ title, body }) {
  return (
    <div className="col-md-4 d-flex mb-5 mb-md-0">
      <div className="services-wrapper">
        <div className="services-card py-4">
          <img src="./assets/images/code.svg" alt="services" className="px-4 py-4" />
          <h3 className="services-title px-4 text-info">{title}</h3>
          <p className="services-text px-4">{body} </p>{" "}
        </div>
      </div>
    </div>
  );
}

export default Card;
