import React from "react";
import Terms from "../component/Terms";


function Myterms() {
    return(
        <Terms/>
    );
}

export default Myterms;