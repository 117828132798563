import React from 'react';

function TestCard() {
  return (
    <div className="row">
      <div className="col-md-4">
        <div className="testimonial text-center">
          <div className="testimonial-icon">
            <i className="fas fa-quote-left"></i>
          </div>
          <p className="lead">
            Their team exhibits professionalism, expertise, and a dedication to client satisfaction that truly sets them apart.
          </p>
          <div className="testimonial-author">
            <h5>Tope Daniel</h5>
            <p className="text-muted">Product Manager at Barbers Limited, UK</p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="testimonial text-center">
          <div className="testimonial-icon">
            <i className="fas fa-quote-left"></i>
          </div>
          <p className="lead d-flex">
            LagCloud Solutions is a game-changer in the IT consultancy realm.
            From intricate software development to robust infrastructure setups, LagCloud Solutions ensures every aspect of your IT needs is met with precision.
          </p>
          <div className="testimonial-author">
            <h5 className="mt-2">Folarin Odeyemi</h5>
            <p className="text-muted">CTO at Openframeworks Limited, Nigeria</p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="testimonial text-center">
          <div className="testimonial-icon">
            <i className="fas fa-quote-left"></i>
          </div>
          <p className="lead d-flex">
            With LagCloud Solutions by your side, navigating the complexities of modern technology becomes effortless.
            Highly recommend their services for any organization seeking top-tier IT consultancy
          </p>
          <div className="testimonial-author">
            <h5 className="mt-2">Babatomide Aduroja</h5>
            <p className="text-muted">CEO at Integrated Multimedia, UK</p>
          </div>
        </div>
      </div>


    </div>
  );
}

export default TestCard;
