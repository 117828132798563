 const mydata = [
     {
         title:"Web Design & Development",
         body:"From stunning user interfaces to powerful back-end functionality, we excel at delivering web applications that captivate and perform."
     },
     {
        title:"Mobile App Development",
        body:"Whether it's iOS or Android, we craft native and hybrid mobile apps that engage and retain users."
    },
    {
        title:"Custom Software Solutions",
        body:"Our team collaborates with you to create software solutions that cater to your unique business needs. Think inventory management systems, project management tools, and more."
    }
 ]

 export default mydata;